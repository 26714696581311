<template>
  <div class="page-wrapper">
    <Navbar />
    <div class="container">
      <Form
        title="Grant requirements"
        subtitle="It takes less than 1 minute to answer these questions and see grants relevant to your business."
        info="Please note the tool is only available for UK domiciled businesses."
        :loading="loading"
        :form="form"
        @submit="submit"
      />
      <Footer />
    </div>
  </div>
</template>

<script>
import Form from '@product/features/products/components/Form'
import Navbar from '../components/Navbar'
import grantForm from './grant.form'
import mapsFields from '@/mixins/mapsFields'
import Footer from '@/components/Footer'
import checkGrantFinderRedirects from '../mixins/checkGrantFinderRedirects'
import { amplitudeEvents } from '@/utils/constants'
import { getMetaData } from '@/api/metaData/index'
import { getFundingPurposeList } from '@/api/pageMetadata/index'
import { getPostcode } from '@/api/postcodes/index'
import { notificationRoutine } from '@/store/modules/notification/routines'
import { toastTopCenterOptions } from '@/config/vue-toast'

export default {
  components: { Form, Navbar, Footer },
  mixins: [mapsFields, checkGrantFinderRedirects],
  data() {
    return {
      loading: true,
      form: []
    }
  },
  async created() {
    this.getFormMetadata()
  },
  mounted() {
    this.trackPageView()
  },
  methods: {
    /**
     * Fetches field metadata, waterFall select options and dynamic forms list.
     * Then creates the form.
    */
    getFormMetadata() {
      Promise.all([getMetaData(['regions', 'sectors'], 'uk'), getFundingPurposeList()]).then(([metadataResponse, fundingPurposeResponse]) => {
        const entitiesMetadata = metadataResponse.data
        entitiesMetadata.fundingPurposeList = fundingPurposeResponse.data

        this.form = grantForm(entitiesMetadata)
        this.attachFormWatchers()
        this.loading = false
      }).catch(e => {
        console.log(e)
      })
    },
    trackPageView() {
      this.$ma.trackEvent(amplitudeEvents.lloydsSMEGrants.LANDING_PAGE_VIEW_GRANTS_FORM)
    },
    attachFormWatchers() {
      this.watchField('company', company => {
        if (company?.registeredAddress) {
          this.attachToFieldValue('registeredAddress.postcode', company.registeredAddress.postcode)
        }
      })
    },
    trackMainCTAEvent(data) {
      getPostcode(data.registeredAddress.postcode)
        .then(({ data: { result } }) => {
          this.$ma.trackEvent(amplitudeEvents.lloydsSMEGrants.GRANTS_FORM_SUBMITTED)

          this.$ma.setUserProperties({
            'Company Structure': this.getSelectedOptionLabel('companyStructures'),
            Amount: data.totalFundingRequired,
            Activities: this.getSelectedOptionLabels('fundingPurposes'),
            Turnover: data.turnoverLastYear,
            Sector: this.getSelectedOptionLabels('sectors'),
            District: result ? result.admin_district : ''
          })
          this.loading = false
          this.$router.push({ name: 'lloyds-grants' })
        })
        .catch(e => {
          this.loading = false
          console.error(e)
          this.$store.dispatch(notificationRoutine.TRIGGER, {
            title: 'API Error',
            options: { ...toastTopCenterOptions, className: 'toasted-error' },
            text: `${e.response?.data?.error || 'Something went wrong'}`
          })
        })
    },
    submit(data) {
      this.saveLocalStorage(data)
      this.trackMainCTAEvent(data)
    },
    saveLocalStorage(data) {
      localStorage.setItem('formData', JSON.stringify(data))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  max-width: 900px;
  padding-bottom: 1rem;
  padding-top: 30px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 1rem;
  }
}
</style>
