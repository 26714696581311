export default (metadataProps) => {
  return [
    {
      type: 'company-search',
      name: 'company',
      label: 'What is the name of your business?',
      mode: 'aggressive',
      value: '',
      rules: 'required',
      disabledTextAutoComplete: true
    },
    {
      type: 'select',
      name: 'companyStructures',
      label: 'What is your business structure?',
      options: [
        { value: '1', label: 'Limited Company' },
        { value: '2', label: 'LLP' },
        { value: '3', label: 'Sole Trader' },
        { value: '6', label: 'Partnership' },
        { value: '7', label: 'Charity' }
      ],
      value: '',
      rules: 'required',
      tooltip: 'A business structure refers to how a company is organised, in regard to its legal status.'
    },
    {
      type: 'select',
      name: 'registeredAddress.country',
      label: 'Where is your business located?',
      options: [
        {
          label: 'Australia',
          value: '9'
        },
        {
          label: 'Canada',
          value: '32'
        },
        {
          label: 'Ireland',
          value: '81'
        },
        {
          label: 'South Africa',
          value: '162'
        },
        {
          label: 'United Kingdom',
          value: '187'
        },
        {
          label: 'United States of America',
          value: '188'
        }
      ],
      value: '',
      rules: 'required',
      tooltip: "Some grants are specific to your business' location, so we need this information to match you to appropriate grants."
    },
    {
      type: 'select',
      name: 'registeredAddress.region',
      label: 'What country is your business located in?',
      options: metadataProps?.regions || [],
      value: '',
      rules: 'required',
      dependsOn: [
        {
          field: 'registeredAddress.country',
          value: '187'
        }
      ]
    },
    {
      type: 'select',
      name: 'registeredAddress.county',
      label: 'Which county is your business located in?',
      options: [],
      metadata: {
        optionsType: 'counties',
        requiredParentField: 'registeredAddress.region'
      },
      value: '',
      rules: 'required',
      dependsOn: [
        {
          field: 'registeredAddress.region',
          value: '4'
        }
      ]
    },
    {
      type: 'select',
      name: 'registeredAddress.district',
      label: 'Which district is your business located in?',
      options: [],
      metadata: {
        optionsType: 'districts',
        requiredParentField: 'registeredAddress.county'
      },
      value: '',
      rules: 'required',
      dependsOn: [
        {
          field: 'registeredAddress.county',
          value: (field) => !!field.value
        },
        {
          field: 'registeredAddress.region',
          value: '4'
        }
      ]
    },
    {
      type: 'input',
      name: 'registeredAddress.postcode',
      label: 'Postcode',
      value: '',
      rules: 'required'
    },
    {
      type: 'radio',
      name: 'tradingAddressIsDifferent',
      label: 'Does your business use a different trading address?',
      value: '',
      rules: 'required',
      options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      tooltip: 'If you are trading at an address which is different to your registered address, then you may be eligible for further grant support in the trading location.'
    },
    {
      type: 'select',
      name: 'tradingAddress.country',
      label: 'Where is your business trading?',
      value: '',
      rules: 'required',
      options: [
        {
          label: 'Australia',
          value: '9'
        },
        {
          label: 'Canada',
          value: '32'
        },
        {
          label: 'Ireland',
          value: '81'
        },
        {
          label: 'United Kingdom',
          value: '187'
        },
        {
          label: 'United States of America',
          value: '188'
        }
      ],
      dependsOn: [
        {
          field: 'tradingAddressIsDifferent',
          value: true
        }
      ],
      tooltip: "Some grants are specific to your business' location, so we need this information to match you to appropriate grants."
    },
    {
      type: 'select',
      name: 'tradingAddress.region',
      label: 'Which country is your business trading in?',
      value: '',
      rules: 'required',
      options: metadataProps?.regions || [],
      dependsOn: [
        {
          field: 'tradingAddress.country',
          value: '187'
        }
      ]
    },
    {
      type: 'select',
      name: 'tradingAddress.county',
      label: 'Which county is your business trading in?',
      value: '',
      rules: 'required',
      options: [],
      metadata: {
        optionsType: 'counties',
        requiredParentField: 'tradingAddress.region'
      },
      dependsOn: [
        {
          field: 'tradingAddress.region',
          value: '4'
        }
      ]
    },
    {
      type: 'select',
      name: 'tradingAddress.district',
      label: 'Which district is your business trading in?',
      options: [],
      metadata: {
        optionsType: 'districts',
        requiredParentField: 'tradingAddress.county'
      },
      value: '',
      rules: 'required',
      dependsOn: [
        {
          field: 'tradingAddress.county',
          value: (field) => !!field.value
        },
        {
          field: 'registeredAddress.region',
          value: '4'
        }
      ]
    },
    {
      type: 'multiselect',
      name: 'sectors',
      label: 'Which sector(s) do you operate or plan to operate in?',
      value: [],
      rules: 'required',
      options: metadataProps?.sectors || [],
      tooltip: 'This will help us to match you to sector specific grant funds that are currently available.'
    },
    {
      type: 'input',
      dataType: 'currency',
      label: 'How much funding do you require?',
      name: 'totalFundingRequired',
      value: '0',
      icon: 'currency',
      iconPosition: 'left',
      rules: 'required',
      tooltip: 'Some grants only offer a certain amount of funding, so we need to know your funding requirement.'
    },
    {
      type: 'multiselect',
      label: 'What activities would you like to fund?',
      value: [],
      name: 'fundingPurposes',
      rules: 'required',
      options: metadataProps?.fundingPurposeList || [],
      tooltip:
        'Different grants are available depending on what you plan to spend the funds on. For example, some grants are to develop new products or processes, others are to buy new machinery.'
    },
    {
      type: 'radio',
      name: 'isRevenueGenerating',
      label: 'Is your business generating revenue?',
      value: '',
      rules: 'required',
      options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      tooltip: 'Revenue/turnover is the income that your business receives for the sale of goods or services.'
    },
    {
      type: 'input',
      dataType: 'currency',
      label: 'How much revenue has your business generated in the last 12 months?',
      name: 'turnoverLastYear',
      value: '0',
      icon: 'currency',
      iconPosition: 'left',
      rules: 'required',
      dependsOn: [
        {
          field: 'isRevenueGenerating',
          value: true
        }
      ],
      tooltip: "Knowing the previous year's revenue/turnover helps us to match you to grants which require the business to be trading. If you have been operating for less than 12 months please enter your revenue to date."
    }
  ]
}
